import React from 'react';
import Business from 'page/business';
import HeaderImage from "assets/images/ITSM/itsm_header_pic.png";

import ItsmImage1 from "assets/images/ITSM/itsm_pic01.png";
import ItsmImage2 from "assets/images/ITSM/itsm_pic02.png";
import Composition from "assets/images/ITSM/diagram.png";

import { ReactComponent as Error } from "assets/images/ITSM/error_icon.svg";
import { ReactComponent as Performance } from "assets/images/ITSM/performance_icon.svg";
import { ReactComponent as Change } from "assets/images/ITSM/change_icon.svg";
import { ReactComponent as Volume } from "assets/images/ITSM/volume_icon.svg";
import { ReactComponent as Compose } from "assets/images/ITSM/compose_icon.svg";
import { ReactComponent as Backup } from "assets/images/ITSM/backup_icon.svg";
import { ReactComponent as Security } from "assets/images/ITSM/security_icon.svg";
import { ReactComponent as Emergency } from "assets/images/ITSM/emergency_icon.svg";

import "assets/styles/business.css";

export default function Itsm() {


    return (
        <>
            <Business title="ITSM (IT Service Management)" subtitle="원활한 운영 관리를 위해 다양한 측면을 고려한 서비스 제공" image={HeaderImage} />
            <div className="environment">

                <div className="ContentDiv">
                    <div>
                        <img className="textImage" src={ItsmImage1} alt={''} />
                    </div>
                    <div>
                        <div className="smalltitle_frame">ITSM 기반</div>
                        <div className="mainTitle">믿을 수 있는 IT 서비스</div>
                        <div className="mainText">ITMS 기반으로 구축된 유지관리체계를 기반으로 프로젝트의 산출물과<br />
                            유지관리 프로세스를 활용함으로써 신뢰도와 효율 부분에 최적화된 IT 서비스를<br />
                            제공합니다.</div>
                    </div>
                </div>

                <div className="ContentDiv">
                    <div className="back-white">
                        <div className="smalltitle_frame">다양한 관리체계</div>
                        <div className="mainTitle">효율적인 유지 보수를 위한 관리 기반 제공</div>
                        <div className="mainText">시스템의 원활한 운영 및 관리를 위하여 장애 관리, 구성 관리, 용량 관리, 성능 관리,<br />
                            변경 관리, 재해복구 관리, 보안 관리, 백업 및 복구 관리 측면을 고려하여, <br />
                            유지 보수를 효율적으로 운영·관리하도록 통합 운영 관리 기반을 제공합니다.</div>
                    </div>
                    <div>
                        <img className="textImage" src={ItsmImage2} alt={''} />
                    </div>
                </div>

                <div className="process">
                    <div className="smalltitle_frame">제공 서비스</div>
                    <div className="process-title">운영 최적화를 위한 다양한 관리 기반</div>

                    <div className="itsm_service_div">
                        <div className="">
                            <div className="itsm_service_box_div margin-right-24">
                                <div className="service_circle">
                                    <Error />
                                </div>
                                <div className="box_title">장애 관리</div>
                                <div className="box_text">운영단계의 장애를 감지하고, 조치 작업을<br />
                                    수행하고, 장애의 원인을 파악/분석하여<br />
                                    장애 복구 방안을 찾고 예방하는 활동</div>
                            </div>
                            <div className="itsm_service_box_div margin-right-24 margin-top-24">
                                <div className="service_circle">
                                    <Performance />
                                </div>
                                <div className="box_title">성능 관리</div>
                                <div className="box_text">IT 서비스를 제공하는 자원들의 성능을<br />
                                    모니터링하고,  성능 개선 테스트 및<br />
                                    개선 작업을 수행하는 활동</div>
                            </div>
                        </div>

                        <div className="margin-top-84">
                            <div className="itsm_service_box_div margin-right-24">
                                <div className="service_circle">
                                    <Change />
                                </div>
                                <div className="box_title">변경 관리</div>
                                <div className="box_text">시스템 변경 요청사항을 접수하여<br />
                                    적용 여부를 평가하고,<br />
                                    변경 작업을 시스템에 적용하는 활동</div>
                            </div>
                            <div className="itsm_service_box_div margin-right-24 margin-top-24">
                                <div className="service_circle">
                                    <Volume />
                                </div>
                                <div className="box_title">용량 관리</div>
                                <div className="box_text">IT 서비스를 제공하는 자원들<br />
                                    (H/W, S/W, N/W)을 업무처리에 필요한 <br />
                                    용량으로 설계 및 기획하는 활동</div>
                            </div>
                        </div>

                        <div className="">
                            <div className="itsm_service_box_div margin-right-24">
                                <div className="service_circle">
                                    <Compose />
                                </div>
                                <div className="box_title">구성 관리</div>
                                <div className="box_text">서버/네트워크/DB 등 시스템 운영을 위해<br />
                                    필요한 주요 시스템 구성요소를 식별</div>
                            </div>
                            <div className="itsm_service_box_div margin-right-24 margin-top-24">
                                <div className="service_circle">
                                    <Backup />
                                </div>
                                <div className="box_title">백업 관리</div>
                                <div className="box_text">데이터 백업 정책을 수립하고, 정기적으로<br />
                                    데이터 백업을 수행하는 활동<br />
                                    필요시 데이터 복구 및 테스트를<br />
                                    수행하는 활동</div>
                            </div>
                        </div>

                        <div className="margin-top-84">
                            <div className="itsm_service_box_div ">
                                <div className="service_circle">
                                    <Security />
                                </div>
                                <div className="box_title">보안 관리</div>
                                <div className="box_text">보안정책을 수립하고, 침해 사고<br />
                                    여부를 모니터링하며, 침해 사고 발생 시<br />
                                    체계적으로 대응하는 활동</div>
                            </div>
                            <div className="itsm_service_box_div  margin-top-24">
                                <div className="service_circle">
                                    <Emergency />
                                </div>
                                <div className="box_title">재해복구 관리</div>
                                <div className="box_text">비상시 필요한 IT 기술 및 서비스<br />
                                    (H/W, N/W, 응용 프로그램 등)의<br />
                                    연속성을 협의된 시간 내에<br />
                                    제공할 수 있도록 하는 활동</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="process">
                <div className="smalltitle_frame">구성도</div>
                <div className="process-title">안정적인 서비스를 위한 유지관리체계 구성</div>
                <div className="diagram_image_background">
                    <img className="diagram_image" src={Composition} alt={''} />
                </div>
            </div>
        </>
    );
}
