import React from 'react';
import Business from 'page/business';
import HeaderImage from "assets/images/enviroment_header_pic.png";

import EnvImage1 from "assets/images/envsys/_enviroment_pic01.png";
import EnvImage2 from "assets/images/envsys/_enviroment_pic02.png";
import EnvImage3 from "assets/images/envsys/_enviroment_pic03.png";

import { ReactComponent as Search } from "assets/images/envsys/search_icon.svg";
import { ReactComponent as Time } from "assets/images/envsys/time_icon.svg";
import { ReactComponent as Gis } from "assets/images/envsys/gis_icon.svg";
import { ReactComponent as Device } from "assets/images/envsys/device_icon.svg";

import "assets/styles/business.css";

export default function EnvSys() {

    return (
        <>
            <Business title="ENVIRONMENT SYSTEM 환경정보시스템" subtitle="환경정보 시스템 운영자의 업무 개선과 서비스 사용자의 쾌적한 생활 유지 기여" image={HeaderImage} />
            <div className="environment">
                <div className="ContentDiv">
                    <div>
                        <img className="textImage" src={EnvImage1} alt={''} />
                    </div>
                    <div>
                        <div className="smalltitle_frame">데이터 시각화</div>
                        <div className="mainTitle">한눈에 확인하는 데이터</div>
                        <div className="mainText">환경정보시스템은 GIS 정보를 이용하여 다양한 환경 분야의 정보를 한눈에<br />
                            수집・분석할 수 있도록 유연하고 확장성 높은 데이터 구조를 가지고 있으며,<br />
                            다양한 통계적 분석 데이터를 시각화하는 여러 형태의 차트, 표 등의 시각화<br />
                            서비스를 제공합니다.</div>
                    </div>
                </div>
                <div id="autoSupport" className="ContentDiv">
                    <div className="back-white">
                        <div className="smalltitle_frame">자동 기능 지원</div>
                        <div className="mainTitle">효율적이고 정확한 업무 지원</div>
                        <div className="mainText">다수의 보건환경연구기관에서 대기 질, 수질 등의 분석을 위해 다빈치뷰의<br />
                            시스템이 활용되고 있습니다. 시민들에게 빠르고 정확한 정보 전달을 위한 경보제<br />
                            알람 문자 서비스를 자동으로 제공함으로써 업무 시간 단축과 함께, 정확한 분석이<br />
                            이루어질 수 있도록 서비스되고 있습니다.</div>
                    </div>
                    <div>
                        <img className="textImage" src={EnvImage2} alt={''} />
                    </div>
                </div>
                <div className="ContentDiv">
                    <div>
                        <img className="textImage" src={EnvImage3} alt={''} />
                    </div>
                    <div>
                        <div className="smalltitle_frame">All-In-One</div>
                        <div className="mainTitle">다양한 데이터도 한 개의 시스템으로</div>
                        <div className="mainText ">환경정보시스템은 대기, 산성우, VOC(휘발성유기화합물), 수질 등<br />
                            각기 다른 특성의 데이터를 한 개의 시스템에서 실시간 자료 수집 및 분석까지<br />
                            자동으로 제공합니다. 본 솔루션은 다빈치뷰가 보유한 특허를 기반으로<br />
                            각 지자체의 미세먼지 청소차량의 효율적인 운행 경로까지 제공가능합니다. </div>
                    </div>
                </div>

                <div id="envSolution" className="process">
                    <div className="smalltitle_frame">솔루션</div>
                    <div className="process-title">통합대기환경 모니터링 시스템</div>
                    <div className="env-monitoring">
                        <div className="env-monitoringBox margin-right-24">
                            <div className="env-boxCircle">
                                <Search />
                            </div>
                            <div className="env-boxText">다양한 조회 조건들을 활용하여<br />
                                사용자가 요구하는 정보를 조회</div>
                        </div>
                        <div className="env-monitoringBox margin-right-24 margin-top-84">
                            <div className="env-boxCircle">
                                <Time />
                            </div>
                            <div className="env-boxText">실시간 데이터를 확인하여 대기 질 문제와<br />
                                데이터 송수신 문제 등 빠른 대응 가능</div>
                        </div>
                        <div className="env-monitoringBox margin-right-24">
                            <div className="env-boxCircle">
                                <Gis />
                            </div>
                            <div className="env-boxText">GIS(Geographic Information System)를<br />
                                활용하여 측정소 위치마다 수집하는<br />
                                데이터를 조회</div>
                        </div>
                        <div className="env-monitoringBox margin-top-84">
                            <div className="env-boxCircle">
                                <Device />
                            </div>
                            <div className="env-boxText">웹・모바일 등 디바이스에 맞는 UI/UX를<br />
                                디자인 및 설계하여 사용자가 이용하는데<br />
                                효율적으로 사용할 수 있게 서비스를 제공</div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}
