import React from 'react';
import { ReactComponent as LogoGray } from "assets/images/logo_gray.svg";

import "assets/styles/index.css";

export default function Footer() {

    return (
        <div className="footer-background">
            <div className="footer">
                <div className="footer-logo">
                    <LogoGray/>
                </div>
                <div>
                    <div className="valuetext"><span className="sub">ADD</span>(21655) 인천광역시 남동구 호구포로 194, 마크원 지식산업센터 1303호</div>
                    <div className="details-div">
                        <div className="valuetext"><span className="sub">TEL</span>032. 715. 6384</div>
                        <div className="valuetext"><span className="sub">대표이사</span>이 현 신</div>
                        <div className="valuetext"><span className="sub">사업자등록번호</span>792-88-01203</div>
                    </div>
                </div>
                <div className="copyright valuetext">© da-Vinciview Co., Ltd. All rights reserved.</div>
            </div>
        </div>
    )
};

