import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';

import MainHeaderImage from "assets/images/main/main_header_bg.png";
import Maingraphic from "assets/images/main/main_graphic.png";
import System from "assets/images/main/landing_pic01.png";
import Enviroment from "assets/images/main/landing_pic02.png";
import Healthcare from "assets/images/main/landing_pic03.png";

import { ReactComponent as Map } from "assets/images/main/map_icon.svg";
import { ReactComponent as Know } from "assets/images/main/knowhow_icon.svg";
import { ReactComponent as Env } from "assets/images/main/env_icon.svg";
import { ReactComponent as Citizen } from "assets/images/main/citizen_icon.svg";
import { ReactComponent as Pro } from "assets/images/main/pro_icon.svg";
import { ReactComponent as Sw } from "assets/images/main/sw_icon.svg";
import { ReactComponent as Arrow } from "assets/images/Arrow.svg";

import "assets/styles/main.css";
import "assets/styles/index.css";
import 'aos/dist/aos.css';

export default function Main() {
    AOS.init();

    return (
        <div >
            <img id="main-header-image" src={MainHeaderImage} alt={''} />
            <div className="main" >

                {/* 랜딩 */}
                <div className="landing-background">
                    <div className="landing" data-aos="fade-up" data-aos-duration="1500">
                        <div className="title">세상과 사람을 이어주는<br /> 네트워크 기술</div>
                        <div className="subtitle">다빈치뷰의 혁신 서비스 플랫폼으로 실현하겠습니다.</div>
                        <div className="scroll-round">
                            <div className="scroll-circle"></div>
                        </div>
                        <div className="scrolltext">SCROLL DOWN</div>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="1500">
                        <img id="float_image" className="main-image" src={Maingraphic} alt={''} />
                    </div>
                </div>

                <div className="our-business">da Vinciview BUSINESS</div>

                {/* ITSM */}
                <div className="main-businessdiv" data-aos="fade-up" data-aos-duration="1500">
                    <div >
                        <div className="smalltitle_frame">SYSTEM MANAGEMENT</div>
                        <div className="subtitle">안정적인 관리를 위한 맞춤형 지원 서비스</div>
                        <div className="title">ITSM기반 유지관리체계로<br />
                            운영의 효율성과 편의성 확보</div>
                        <div className="maintext">통합 운영 관리를 기반으로 안정적이고 효율적인 서비스 운영을 <br />
                            지원합니다. 다양한 상황과 사용자 요청에 맞춘 관리체계로<br />
                            믿을 수 있는 IT 서비스를 만들어 나갑니다.</div>
                        <Link to='/itsm' className="nodecoLink">
                            <div className="datail-btn">+ 자세히 알아보기</div>
                        </Link>
                    </div>
                    <div>
                        <img className="landing-image" src={System} alt={''} />
                    </div>
                </div>

                {/* ENV System */}
                <div id="envsys" className="main-businessdiv" data-aos="fade-up" data-aos-duration="1500">
                    <div>
                        <img className="landing-image" src={Enviroment} alt={''} />
                    </div>
                    <div >
                        <div className="smalltitle_frame">ENVIRONMENT SYSTEM</div>
                        <div className="subtitle">대기 환경 측정기 임베디드 SW 및 데이터마이닝 솔루션</div>
                        <div className="title">최적화된 서비스로 관리되는<br />
                            대기・환경 정보 데이터</div>
                        <div className="maintext">GIS를 활용하여 정보를 한눈에 수집・분석할 수 있는 <br />
                            서비스를 제공합니다. 지역 환경 요소에 특화된 관제 시스템으로 <br />
                            정확한 정보 제공 및 분석이 가능합니다.</div>
                        <Link to='/envirment' className="nodecoLink">
                            <div className="datail-btn">+ 자세히 알아보기</div>
                        </Link>
                    </div>
                </div>

                {/* healthCare */}
                <div className="main-businessdiv" data-aos="fade-up" data-aos-duration="1500">
                    <div >
                        <div className="smalltitle_frame">HEALTHCARE SW</div>
                        <div className="subtitle">환자 중심의 헬스케어 마켓 서비스 플랫폼</div>
                        <div className="title">실시간 건강 상태 기록으로<br />
                            정확한 진료와 효과적인 치료</div>
                        <div className="maintext">참여 기반의 환자 중심 헬스케어 플랫폼을 제공합니다.<br />
                            ICT를 접목한 실시간 헬스 모니터링 서비스로 진료의 <br />
                            정확성을 높이고, 효과적인 치료를 진행합니다.</div>
                        <Link to='/healthcare' className="nodecoLink">
                            <div className="datail-btn">+ 자세히 알아보기</div>
                        </Link>
                    </div>
                    <div >
                        <img className="landing-image" src={Healthcare} alt={''} />
                    </div>
                </div>

                {/* Service */}
                <div id="service" className="main_service_div">
                    <div className="smalltitle_frame">서비스 제공 분야</div>
                    <div className="main_service_title">다빈치뷰는 환경 모니터링 및 헬스케어 분야의 끊임없는 혁신 기술 개발로<br />
                        사람들의 더 높은 삶의 질을 위한 공공 서비스를 제공합니다.</div>
                    <div className="subtitle-boxdiv-background">
                        <div>
                            <div className="subtitle-boxbackground">
                                <div className="circle-image"> <Map /></div>
                                <div className="circle-title">맞춤형 서비스 개발</div>
                                <div className="circle-text">전국/지차체 구성 및 환경 맞춤형<br />
                                    데이터 수집 장치 구축</div>
                            </div>
                            <div className="subtitle-boxbackground">
                                <div className="circle-image"> <Citizen /></div>
                                <div className="circle-title">대시민 서비스 개발</div>
                                <div className="circle-text">시민 생활 밀착형 공공서비스 개발 및
                                    관제 시스템 구축</div>
                            </div>
                        </div>
                        <div className="top64">
                            <div className="subtitle-boxbackground">
                                <div className="circle-image"> <Know /></div>
                                <div className="circle-title">축적된 노하우로 안정적인 서비스 운영</div>
                                <div className="circle-text">풍부한 경험 및 기술 기반의 <br />
                                    측정 현장 상태 분석 전문 인력 투입</div>
                            </div>
                            <div className="subtitle-boxbackground">
                                <div className="circle-image"> <Pro /></div>
                                <div className="circle-title">헬스케어 솔루션 개발</div>
                                <div className="circle-text">PRO 기반의 헬스케어 및<br />
                                    임상시험 매칭 서비스 제공</div>
                            </div>
                        </div>
                        <div>
                            <div className="subtitle-boxbackground">
                                <div className="circle-image"> <Env /></div>
                                <div className="circle-title">환경정보 서비스 시스템 구축</div>
                                <div className="circle-text">Data Visualization & Data Mining 기술 기반의<br />
                                    환경정보 서비스 제공</div>

                            </div>
                            <div className="subtitle-boxbackground">
                                <div className="circle-image"> <Sw /></div>
                                <div className="circle-title">국제 수준의 소프트웨어 공학 기술 운영</div>
                                <div className="circle-text">국제 모델 및 개발 방법론을 기반으로<br />
                                    체계적인 시스템 구축 및 유지관리 서비스 제공</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Company */}
            <div className="main_company_background">
                <div className="main_company_div">
                    <div className="title">내일을 보는 기술이 현실에 연결되는 세상</div>
                    <div className="subtitle">지속적인 기술 혁신으로 고객 가치를 실현합니다.</div>
                    <Link to='/company' className="nodecoLink">
                        <div className="button">CHECK OUT COMPANY <Arrow className="arrow" /></div>
                    </Link>
                </div>
            </div>

        </div>
    );
}
