import React from 'react';
import { Link,useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from "assets/images/logo.svg";

import "assets/styles/index.css";

export default function Header() {
    const { pathname } = useLocation();
    return (
        <div className="Header-background">
            <div className="Header">
                <Link to='/' className="nodecoLink">
                    <div className="logo">
                        <Logo />
                    </div>
                </Link>
                <div className="menuBar">
                    <Link to='/itsm' className="nodecoLink">
                        <div className={pathname === '/itsm' ? "clickmenu" : "menu"}>ITSM</div>
                    </Link>
                    <Link to='/envirment' className="nodecoLink">
                        <div className={pathname === '/envirment' ? "clickmenu" : "menu"} >ENVIRONMENT SYSTEM</div>
                    </Link>
                    <Link to='/healthcare' className="nodecoLink">
                        <div className={pathname === '/healthcare' ? "clickmenu" : "menu"} >HEALTHCARE SW</div>
                    </Link>
                    <Link to='/portfolio' className="nodecoLink">
                        <div className={pathname === '/portfolio' ? "clickmenu" : "menu"} >PORTFOLIO</div>
                    </Link>
                    <Link to='/company' className="nodecoLink">
                        <div className={pathname === '/company' ? "clickmenu" : "menu"} >COMPANY</div>
                    </Link>
                </div>
            </div>
        </div>
    )
};

