import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from 'page/header';
import Footer from 'page/footer';
import ScrollToTop from 'page/scroll/inedx';

import Main from 'page/main';
import Itsm from 'page/itsm';
import EnvSys from 'page/envsys';
import Healthcare from 'page/healthcare';
import Portfolio from 'page/portfolio';
import Company from 'page/company';


function App() {
  return (
    <BrowserRouter >
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" exact element={<Main />} />
        <Route path="/itsm" exact element={<Itsm />} />
        <Route path="/envirment" exact element={<EnvSys />} />
        <Route path="/healthcare" exact element={<Healthcare />} />
        <Route path="/portfolio" exact element={<Portfolio />} />
        <Route path="/company" exact element={<Company />} />
      </Routes>
      <Footer />
    </BrowserRouter>

  )
}

export default App;
