import React from 'react';

// import { ReactComponent as Vision } from "assets/images/company_vision.svg";
import Vision from "assets/images/vision_pic.png";

import Partner from "assets/images/partner_pic.png";
import Map from "assets/images/map.png";
import Sign from "assets/images/ceoSign.png";

import "assets/styles/company.css";

export default function Company() {

    function GoNaver() {
        window.open("http://naver.me/Gi9FDGlO","_blank");
    }

    return (
        <>
            <div className="company-header-background">
                <div className="company-header" >
                    <div className="header-frame">COMPANY</div>
                    <div className="header-Title">기술을 통해 나아가는 세상</div>
                    <div className="header-main">다양한 경험과 지속적인 기술 개발로<br />
                        삶의 질 향상에 기여합니다.</div>
                </div>
            </div>
            <div className="company">
                <div className="compoment">
                    <div className="smalltitle_frame">VISION</div>
                    <div className="subtitle">High mature technology, High innovative solution <br />Leader for Providing High customer satisfaction</div>
                    <div className="main padding-top-80">
                        <div>
                            <img className="visionImage" src={Vision} alt={''} />
                        </div>
                        <div className="maintext">컴포넌트/데이터 연계 기술과 센싱 컨트롤, 데이터마이닝 기술을 통해 고객에게 가치의 감동을 전하는 서비스 플랫폼 전문 기업입니다.<br /><br />
                            데이터 아키텍처, 센싱 네트워크, 서비스 모델링 등 서비스・시스템 분야에 대한 전문화된 컨설팅 및 시스템 SW 개발 뿐만 아니라,
                            대기 환경요소 측정 데이터 관리 시스템에 대한 분석 및 아키텍처, 지역 맞춤형 정보 서비스 등 환경 정보 분야의 데이터 분석 플랫폼을 개발하고 공급하고 있습니다.<br />
                            또한 환자 중심의 건강 상태 데이터를 기반으로 환자 맞춤형 건강 모니터링, 건강식품 온라인 마켓, 임상시험 프로그램 연계 등 헬스케어분야의 서비스 플랫폼을 개발하고 제공하고 있습니다. <br /><br />
                            전문화된 환경・보건 데이터 분석 기술과 솔루션으로 고객 만족을,<br />
                            지속적인 기술 혁신을 통해 잠재적 고객 가치 실현을 약속드립니다.
                        </div>
                    </div>
                </div>
            </div>

            <div className="compoment ceo-dackground">
                <div className="company ">
                    <div className="padding-top-80">
                        <div className="smalltitle_frame">CEO GREETING</div>
                        <div className="main">
                            <div className="subtitle width40">High mature technology<br />High innovative solution</div>
                            <div className="maintext width60">다빈치뷰는 환경・보건 분야의 빅데이터 분석과 시스템 개발을 통해 지역 맞춤형 대민 서비스를 개발하고 있습니다. 지속적인 기술 혁신을 통해 앞선 기술을 선보이는, 고객에게 조금 더 적극적으로 다가가는 혁신 기업이 될 것을 약속드립니다.</div>
                        </div>
                        
                        <div className="sign">다빈치뷰 CEO<span className="CEO">이  현  신</span> <img className="sign-image" src={Sign} alt={''} /> </div>
                    </div>
                </div>
            </div>

            <div className="compoment company">
            <div className="smalltitle_frame">PARTNER</div>
                <div className="subtitle">우수기관들과 협력을 통해 환경보건분야의 다양한 고객에게 <br />앞선 서비스와 혁신 기술을 제공하겠습니다.</div>
            </div>
            <div className="partner">
            </div>
            <div className="partner-iamge-position">
            <img className="partner-image" src={Partner} alt={''} />
            </div>
            <div className="company endline">
                <div className="compoment contactus">
                    <div className="width40">
                        <div className="smalltitle_frame">PARTNER</div>
                        <div className="subtitle">오시는길</div>
                        <div className="contacttext">인천 남동구 호구포로 194 , 마크원 지식산업센터 1303호<br />
                            <span className="contacttext-detail">GATE 1번으로 들어오시거나 ENTRANCE A로 들어와 엘리베이터를 이용해주세요.</span> <br /><br />
                            <span className="contact-type">지하철</span> 수인분당선 호구포역 1번 출구 도보 10분<br />
                            <span className="contact-type">버 &nbsp;&nbsp; 스</span> 해양기계 정류장 하차 후 도보 1분<br />
                            <span className="contact-type">자가용</span> 남동IC에서 차량 이동 20분</div>
                        <div className="contactus">
                            <div className="width50">
                                <div className="subtitle">대표번호</div>
                                <div className="contacttext">032. 715. 6384</div>
                            </div>
                            <div className="width50">
                                <div className="subtitle">팩스</div>
                                <div className="contacttext">032. 888. 6230</div>
                            </div>
                        </div>
                        <div className="subtitle">대표메일</div>
                        <div className="contacttext">davinciview@da-vinciview.com</div>
                        <div className="go-naver-map" onClick={GoNaver}>네이버 지도로 확인하기</div>
                    </div>
                    <div className="width60">
                        <img className="map-image" src={Map} alt={''} />
                    </div>
                </div>
            </div>
        </>
    );
}
