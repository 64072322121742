import React from "react";
import Founded from "assets/images/certification/paper01.png";
import Laboratory from "assets/images/certification/paper02.png";
import Software from "assets/images/certification/paper03.png";
import Vent from "assets/images/certification/paper04.png";

import "assets/styles/portfolio.css";

export default function Portfolio() {
  // useEffect(() => {
  //     setTimeout(() => {
  //         document.location.href = "/monitoring"
  //     }, 2000);
  //   }, []);

  return (
    <div className="portfolio_background">
      <div className="portfolio">
        <div className="smalltitle_frame">인증현황</div>
        <div className="title">인정받은 기술로 혁신을 만들어갑니다</div>
        <div className="certification_image_background">
          <div className="certification_image_div">
            <img className="image" src={Founded} alt={""} />
            <img className="image" src={Laboratory} alt={""} />
            <img className="image" src={Software} alt={""} />
            <img className="image" src={Vent} alt={""} />
          </div>
        </div>
        <div className="smalltitle_frame">회사 연혁</div>
        <div className="title">기술의 기반이 되는 다양한 경험들</div>
        <div className="history_background">
          <div className="company-list">
            <div>
              <CompanyStatusBox day={"2018. 06"} text={"회사 설립"} />
              <CompanyStatusBox day={"2019. 11"} text={"대표이사 변경"} />
              <CompanyStatusBox
                day={"2021. 01"}
                text={"성진종합전기(주) 친환경 변압기 상태관리시스템 MOU 체결"}
              />
              <CompanyStatusBox
                day={"2021. 07"}
                text={"아시아경제TV 클로즈업기업현장 방송 출연"}
              />
              <CompanyStatusBox
                day={"2022. 01"}
                text={"인천광역시 BusTMS 시내버스 운송관리지원시스템 유지관리"}
              />
              <CompanyStatusBox
                day={"2022. 07"}
                text={"녹색혁신상생협력 설비 관제시스템 고도화"}
              />
              <CompanyStatusBox
                day={"2023. 02"}
                text={"실시간모기발생정보 관제시스템 유지관리"}
              />
              <CompanyStatusBox
                day={"2024. 01"}
                text={"인천데이터포털 유지관리"}
              />
              <CompanyStatusBox
                day={"2024. 01"}
                text={"옹진군 항공기소음 측정망 구축"}
              />
            </div>
            <div className="marginleft24 ">
              <CompanyStatusBox
                day={"2018. 07"}
                text={"보안 인증 모듈 ETRI 납품"}
              />
              <CompanyStatusBox
                day={"2020. 02"}
                text={"환자보고성과 시스템 납품"}
              />
              <CompanyStatusBox
                day={"2021. 01"}
                text={"인천광역시 보건환경연구원 대기환경정보시스템 유지보수"}
              />
              <CompanyStatusBox
                day={"2021. 10"}
                text={"인천테크노파크 녹색혁신 상생협력 설비 관제시스템 구축"}
              />
              <CompanyStatusBox
                day={"2022. 01"}
                text={"인천광역시 버스정보시스템 기능개선"}
              />
              <CompanyStatusBox
                day={"2023. 01"}
                text={"시내버스 운송관리지원시스템 유지관리"}
              />
              <CompanyStatusBox
                day={"2023. 10"}
                text={"B2B 주류 유통 플랫폼 구축"}
              />
              <CompanyStatusBox
                day={"2024. 01"}
                text={"대기환경정보시스템 유지관리"}
              />
              <CompanyStatusBox
                day={"2024. 01"}
                text={"양천구 항공기소음 측정망 구축"}
              />
            </div>
            <div className="marginleft24">
              <CompanyStatusBox
                day={"2018. 11"}
                text={"네트워크 변이 플랫폼 ETRI 납품"}
              />
              <CompanyStatusBox
                day={"2020. 12"}
                text={"서울산업진흥원 올해의 G밸리 10대 기업 선정"}
              />
              <CompanyStatusBox
                day={"2021. 02"}
                text={
                  "인천보건환경연구원 실시간모기발생정보모니터링 정보화부문 유지보수"
                }
              />
              <CompanyStatusBox
                day={"2021. 11"}
                text={"인천테크노파크 녹색혁신사업 로드맵 구축 "}
              />
              <CompanyStatusBox
                day={"2022. 02"}
                text={
                  "인천보건환경연구원 실시간모기발생정보모니터링 정보화부문 유지보수"
                }
              />
              <CompanyStatusBox
                day={"2023. 01"}
                text={"대기환경정보시스템 유지관리"}
              />
              <CompanyStatusBox
                day={"2023. 10"}
                text={"시설공사 하자관리 시스템 구축"}
              />
              <CompanyStatusBox
                day={"2024. 01"}
                text={"시내버스 운송관리지원시스템 유지관리"}
              />
              <CompanyStatusBox
                day={"2024. 02"}
                text={"실시간모기발생정보 관제시스템 유지관리"}
              />
            </div>
            <div className="marginleft24 ">
              <CompanyStatusBox day={"2019. 10"} text={"상호 변경"} />
              <CompanyStatusBox
                day={"2021. 01"}
                text={"산학협력 협약 체결 (상명대)"}
              />
              <CompanyStatusBox
                day={"2021. 05"}
                text={"충남연구원 마을대기측정망 대시민 서비스 구축"}
              />
              <CompanyStatusBox
                day={"2022. 01"}
                text={"인천광역시 보건환경연구원 대기환경정보시스템 유지보수"}
              />
              <CompanyStatusBox
                day={"2022. 03"}
                text={"센서기반 인터렉티브 미들웨어 개발"}
              />
              <CompanyStatusBox
                day={"2023. 01"}
                text={"버스준공영제 정산시스템 구축"}
              />
              <CompanyStatusBox
                day={"2023. 01"}
                text={"RG 컨시어지 서비스 플랫폼 구축"}
              />
              <CompanyStatusBox
                day={"2024. 01"}
                text={"육군 자동소음 측정망 시범사업 구축"}
              />
              <CompanyStatusBox
                day={"2024. 02"}
                text={"대기환경정보관리시스템 유지관리"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CompanyStatusBox(props) {
  return (
    <div className="yearbox">
      <div className="year">{props.day}</div>
      <div className="text">{props.text}</div>
    </div>
  );
}
