import React from 'react';

// import { ReactComponent as Frame } from "assets/images/Frame.svg";

import "assets/styles/compoment.css";

export default function Business(props) {

    return (
        <div className="business-background">
            <img className="image" src={props.image} alt={''} />
            <div className="business">
                <div className="">
                    <div className="mark">BUSINESS</div>
                    <div className="title">{props.title}</div>
                    <div className="subtitle">{props.subtitle}</div>
                </div>
            </div>
        </div>

    )
};

