import React from 'react';
// import { ReactComponent as Vision } from "assets/images/company_vision.svg";
import Business from 'page/business';
import HeaderImage from "assets/images/health_header_pic.png";

import HelImage1 from "assets/images/health/health_pic01.png";
import HelImage2 from "assets/images/health/health_pic02.png";
import HelImage3 from "assets/images/health/health_pic03.png";
import ProcessLine from "assets/images/health/_process_line.png";

import { ReactComponent as PushIcon } from "assets/images/health/push_icon.svg";
import { ReactComponent as DateIcon } from "assets/images/health/date_icon.svg";
import { ReactComponent as WriteIcon } from "assets/images/health/write_icon.svg";
import { ReactComponent as CareIcon } from "assets/images/health/care_icon.svg";


import "assets/styles/business.css";

export default function Healthcare() {

    // useEffect(() => {
    //     setTimeout(() => {
    //         document.location.href = "/monitoring"
    //     }, 2000);
    //   }, []);

    return (
        <>
            <Business title="HEALTHCARE SW 환자 보고 성과 시스템" subtitle="거리가 멀어도 실시간으로 함께 하는 건강관리" image={HeaderImage} />
            <div className="healthcare">
                <div className="ContentDiv">
                    <div>
                        <img className="textImage" src={HelImage1} alt={''} />
                    </div>
                    <div>
                        <div className="smalltitle_frame">실시간 증상 기록</div>
                        <div className="mainTitle">정확한 진료를 위한 실시간 증상 기록</div>
                        <div className="mainText">환자 보고 성과 시스템은 환자가 병원에 내원하기 전에 발생한 증상에 대해서 <br />
                            실시간으로 기록하여 병원 내원 시 정확한 진료를 받을 수 있는 기반 자료로 활용<br />
                            될 수 있습니다.</div>
                    </div>
                </div>
                <div className="ContentDiv">
                    <div>
                        <div className="smalltitle_frame">ICT 기술 접목</div>
                        <div className="mainTitle">더 똑똑해지는 건강관리</div>
                        <div className="mainText">환자들의 기억에 의존해야 했던 시스템에 ICT 기술을 접목하여 <br />
                            실시간 증상 감시 체계를 제공합니다. 실시간으로 증상에 대한 기록이 가능하기 <br />
                            때문에 진료의 정확성을 높일 수 있고, 이와 병행하여 증상별 중증 정도를 <br />
                            환자에게 제공해 환자 스스로 건강관리를 할 수 있도록 도움을 줍니다.</div>
                    </div>
                    <div>
                        <img className="textImage" src={HelImage2} alt={''} />
                    </div>
                </div>
                <div className="ContentDiv">
                    <div>
                        <img className="textImage" src={HelImage3} alt={''} />
                    </div>
                    <div>
                        <div className="smalltitle_frame">참여 기반 플랫폼</div>
                        <div className="mainTitle">소통을 통한 효과적인 치료</div>
                        <div className="mainText">소통과 공유가 가능한 참여 기반 플랫폼으로 환자가 자신의 질병, 상태, 치료 후 <br />
                            증상을 실시간으로 확인하거나 공유함으로써 효과적인 치료 진행을 기대해 볼 수<br />
                            있습니다. </div>
                    </div>
                </div>

                {/* 프로세스 */}
                <div className="process">
                    <div className="smalltitle_frame">프로세스</div>
                    <div className="process-title">증상 기록부터 건강관리까지 한번에</div>
                    <img className="pro-lineImage" src={ProcessLine} alt={''} />
                    <div id="pro-step-div">
                        <div className="pro-step-box margin-right-24">
                            <div className="step">STEP 01.</div>
                            <div className="pro-circle">
                               <PushIcon/>
                            </div>
                            <div className="pro-text">설문지를 생성 후 배포하면<br />
                                환자에게 설문 작성을 요청하는<br />
                                PUSH 메시지가 발송됩니다.</div>
                        </div>
                        <div className="pro-step-box margin-right-24">
                            <div className="step">STEP 02.</div>
                            <div className="pro-circle">
                                <DateIcon/>
                            </div>
                            <div className="pro-text">환자는 정해진 주기에 맞춰<br />
                                증상을 기록합니다.</div>
                        </div>
                        <div className="pro-step-box margin-right-24">
                            <div className="step">STEP 03.</div>
                            <div className="pro-circle">
                                <WriteIcon/>
                            </div>
                            <div className="pro-text">환자가 작성한 증상 기록을 수신하여<br />
                                결과에 대한 소견을 작성합니다.</div>
                        </div>
                        <div className="pro-step-box">
                            <div className="step">STEP 04.</div>
                            <div className="pro-circle">
                                <CareIcon/>
                            </div>
                            <div className="pro-text">환자는 소견을 확인하고,<br />
                                그에 맞는 건강관리가 가능합니다.</div>
                        </div>
                    </div>
                </div>

                {/* <div className="process">
                    <div className="smalltitle_frame">제공기능</div>
                    <div className="process-title">증상 기록부터 결과분석까지 편리하게</div>

                    <div className="pro-serviceDiv">

                        <div className="pro-serviceBox margin-right-24">
                            <div className="pro-BoxText">PRO 데이터 수집률 향상을 위한 <br />
                                환자용 Mobile PRO 서비스</div>
                            <div className="pro-serviceCircle">
                                <div className="pro-circleDiv margin-right-24">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">모바일 서비스</div>
                                </div>
                                <div className="pro-circleDiv">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">PRO 설문 관리 기능</div>
                                </div>
                            </div>
                            <div className="pro-serviceCircle">
                                <div className="pro-circleDiv margin-right-24">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">알람 기능</div>
                                </div>
                                <div className="pro-circleDiv">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">피드백 기능</div>
                                </div>
                            </div>
                        </div>

                        <div className="pro-serviceBox margin-right-24">
                            <div className="pro-BoxText">관리 용이성 제공을 위한 <br />
                                Mobile PRO 관리 서비스</div>
                            <div className="pro-serviceCircle">
                                <div className="pro-circleDiv margin-right-24">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">PRO 설문 관리 기능</div>
                                </div>
                                <div className="pro-circleDiv">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">환자 관리 기능</div>
                                </div>
                            </div>
                            <div className="pro-serviceCircle">
                                <div className="pro-circleDiv margin-right-24">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">설문 자동 배포 기능</div>
                                </div>
                                <div className="pro-circleDiv">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">권한 관리 기능</div>
                                </div>
                            </div>
                        </div>

                        <div className="pro-serviceBox">
                            <div className="pro-BoxText">환자별 맞춤진료를 위한 <br />
                                의사용 Mobile PRO 서비스</div>
                            <div className="pro-serviceCircle">
                                <div className="pro-circleDiv margin-right-24">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">대시보드</div>
                                </div>
                                <div className="pro-circleDiv">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">설문결과 조회</div>
                                </div>
                            </div>
                            <div className="pro-serviceCircle">
                                <div className="pro-circleDiv margin-right-24">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">시각화 기능</div>
                                </div>
                                <div className="pro-circleDiv">
                                    <div className="pro-circle"></div>
                                    <div className="pro-circleText">보고서 기능</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}
